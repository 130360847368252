import {ChainId} from "@pancakeswap/sdk";

export const TUDOG_STATUS = {
  1: 'Listed',
  2: 'Preheating',
  3: 'Pre-sale',
  4: 'Pre-market'
}

export interface TuDogInfo {
  address: string
  chainId: ChainId
  symbol: string
  status: (typeof TUDOG_STATUS)[keyof typeof TUDOG_STATUS]
  intro?: string
  logo: string
  twitter?: string
  telegram?: string
  isNull: boolean
}

export const fetchTuDog = async (chainId: number, langCode: string): Promise<(TuDogInfo)[]> => {
  const response = await fetch(`${process.env.NEXT_PUBLIC_TUDOG_LIST_URL}?chainId=${chainId}&lang=${langCode}`, {
    method: "GET"
  })
  const result = await response.json()
  if (result.code !== 200) return Promise.reject(result?.msg ?? 'Error')


  const list = result.rows
  const tmp = list.filter(i => Number(i.chainId) === chainId).map(i => {
    const item: TuDogInfo = {
      address: i.tokenAddress,
      chainId: Number(i.chainId),
      symbol: i.tokenSymbol || i.tokenName,
      status: TUDOG_STATUS[Number(i.dogStatus)],
      logo: i.tokenLogoUrl,
      intro: i.tokenInfo,
      twitter: i.x,
      telegram: i.tg,
      isNull: false
    }
    return item
  })
  if (tmp.length < 12) {
    const nList = new Array(12 - tmp.length).fill(null)
    return tmp.concat(nList.map((_,i)=>({
      address: `${Date.now()}-${i}`,
      chainId,
      symbol: '',
      status: '',
      logo: '',
      isNull: true
    })))
  }

  return tmp
}